import  React, { useState } from "react"
import Helmet from "react-helmet"
import {Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Row from "../../../components/row"
import Variables from "../../../components/variables"

const Etrasimod = () => {

	const [isChecked0, setIsChecked0] = useState(false); 
const [isChecked1, setIsChecked1] = useState(true);
const [isChecked2, setIsChecked2] = useState(false);
const [isChecked3, setIsChecked3] = useState(false);
const [isChecked4, setIsChecked4] = useState(false);
const [isChecked5, setIsChecked5] = useState(false);
const [isChecked6, setIsChecked6] = useState(false);
const [isChecked7, setIsChecked7] = useState(false);
const [isChecked8, setIsChecked8] = useState(false);
const [isChecked9, setIsChecked9] = useState(false);
const [isChecked10, setIsChecked10] = useState(false);
const [isChecked11, setIsChecked11] = useState(false);	

const handleOnChange0 = () => {
	//select all
	
	if(isChecked0 == false)
		{
		
	   		setIsChecked1(false)
			setIsChecked2(false)
	   		setIsChecked3(false)
	   		setIsChecked4(false)
			setIsChecked5(false)
	   		setIsChecked6(false)
	   		setIsChecked7(false)
			setIsChecked8(false)
	   		setIsChecked9(false)
	   		setIsChecked10(false)
		}
	
	  setIsChecked0(!isChecked0);
	 
};

const handleOnChange1 = () => {
    //etrasimod all
	 if(isChecked1 == false)
	 {
	   setIsChecked0(false)
	   setIsChecked2(true)
	   setIsChecked3(true)
	   setIsChecked4(true)
	 }
	else{
	
	   setIsChecked2(false)
	   setIsChecked3(false)
	   setIsChecked4(false)
	 }
	
	 setIsChecked1(!isChecked1);
 };

  const handleOnChange2 = () => {
 
	if(isChecked2 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked1 == true)
	 {setIsChecked1(false)}
	  
	   setIsChecked2(!isChecked2); 
 };

  const handleOnChange3 = () => {
	
	  if(isChecked3 == false)
		{
			setIsChecked0(false)
		}
	  
	if(isChecked1 == true)
	 {setIsChecked1(false)}
	  
    setIsChecked3(!isChecked3);
};
	
const handleOnChange4 = () => {
	
	  if(isChecked4 == false)
		{
			setIsChecked0(false)
		}
	
	if(isChecked1 == true)
	 {setIsChecked1(false)}
	  
	  
    setIsChecked4(!isChecked4);
};
	

  const handleOnChange5 = () => {
	//olorinab all
	  
	   if(isChecked5 == false)
	 {setIsChecked0(false)
	   setIsChecked6(true)
	   setIsChecked7(true)
	   setIsChecked8(true)
	 }
	else{
		
	   setIsChecked6(false)
	   setIsChecked7(false)
	   setIsChecked8(false)
	 }
	  
    setIsChecked5(!isChecked5);
};


  const handleOnChange6 = () => {
	  if(isChecked6 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
	  
    setIsChecked6(!isChecked6);
 };

  const handleOnChange7 = () => {
	  if(isChecked7 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked7(!isChecked7);
};

  const handleOnChange8 = () => {
	  if(isChecked8 == false)
		{
			setIsChecked0(false)
		}
	  
	  if(isChecked5 == true)
		{
			setIsChecked5(false)
		}
    setIsChecked8(!isChecked8);
 };

  const handleOnChange9 = () => {
	  if(isChecked9 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked9(!isChecked9);
};

  const handleOnChange10 = () => {
	  if(isChecked10 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked10(!isChecked10);
};
	
const handleOnChange11 = () => {
	if(isChecked11 == false)
		{
			setIsChecked0(false)
		}
    setIsChecked11(!isChecked11);
};

	
function renderFilteredData()
	{
		var checkboxStates = [
			[isChecked0, ""],
			[isChecked1, "etrasimod"],
			[isChecked2, "etrasimod-preclinical"],
		    [isChecked3, "etrasimod-phase1"],
			[isChecked4, "etrasimod-phase2"],
			[isChecked5, "olorinab"],
			[isChecked6, "olorinab-preclinical"],
			[isChecked7, "olorinab-phase1"],
			[isChecked8, "olorinab-phase2b"],
			[isChecked9, "sphingosine"],
			[isChecked10, "thromboembolic-events"],
			[isChecked11, "ulcerative-colitis"]
			
		];
		var arrayToCheck = [];
		
		
		for(var i = 0; i<checkboxStates.length; i++ )
		{
			
			if(checkboxStates[i][0]== true){ arrayToCheck.push(checkboxStates[i][1])}	
			//alert(checkboxStates[i][0]);
		}
		if(arrayToCheck.length == 0){ return "";}
		
		//if(checkboxStates[0][0]== true){ arrayToCheck.push(checkboxStates[0][1])}
		//alert(checkboxStates.length);
	return	Variables.keyTopic.filter(item => (  checkStringWithArray(item.filters, arrayToCheck))   ).map((item) =>							   
<a key={item.title} href={item.link} target="_blank" rel="noreferrer">
<>{item.title}</><>{item.label} 
</><div><hr /><img src={"/images/view-button.svg"} alt="View" /></div></a>
					 );

	}
	
	
function checkStringWithArray(theString, theArray)
	{
	if(theString == null) return;
		
		

		for(var i=0; i<theArray.length; i++)
			{
				if( theString.indexOf(theArray[i]) != -1 )
					{ return true;}
			}
			
		return false;
	}

	
return (
  <Layout>
	<Helmet>
        <script src={'/js/main.js'} type="text/javascript" />
	    <script src={'/js/etras-main.js'} type="text/javascript" />
	    <script src={'/js/filter.js'} type="text/javascript" />
    </Helmet>
    <Seo title="Etrasimod" description="Investigational trials and publications archive for etrasimod, a drug candidate for the treatment of immune-mediated and inflammatory diseases" />
    <header className="headline">
	<img src={"/images/our-pipeline-gastroenterology.svg"} alt="Our pipeline gastroenterology" />
	</header>
	<section className="wrap"><h2>We’re pursuing every opportunity to develop and bring our investigational drugs <nobr>to patients</nobr></h2>
	  <br/>
	</section>
	<section className="wrap center narrow">
	<h1 className="drugName">ETRASIMOD</h1>
	<p className="no-top"><strong>A drug candidate for the treatment of immune-mediated and inflammatory diseases</strong><br/></p>
		<p><img src={"/images/subhead-divider.svg"} /></p>
		<p className="product"><strong>Etrasimod</strong> is an investigational, once-daily, oral, selective, S1P receptor modulator designed for engagement of S1P receptors S1P<sub>1</sub>, S1P<sub>4</sub>, and S1P<sub>5</sub>. The purpose of this design is to partially and reversibly reduce lymphocyte levels at sites of inflammation while maintaining components of immune function.</p>

<p>Etrasimod is an investigational drug and is not currently approved for use by any health authority.
This information is not intended to promote or recommend etrasimod for any use.</p>
	</section>
	<section className="wrap">
	<h1 className="special">Proposed etrasimod MECHANISM OF ACTION: </h1>
	<header className="orange rule"><h1 id="s1p-receptor"><span>S1P receptor modulation in IBD</span></h1></header><br className="onlyDesktop" />
		<div className="videoHolder"><iframe src="https://player.vimeo.com/video/668803926?h=57d5f2ba9a" width="1032" height="606" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>
		
	</section>
    <section className="spacer special-mobile-etr"></section>
	<section className="wrap back-to-top"><img src={'/images/back-to-top.svg'} alt="Back to top" id="backToTop" /><header className="orange rule"><h1 id="#est-clinictal">Etrasimod <span>clinical trials in GI DISEASES</span></h1></header>
		<div className="pipeline-row"><div className="phase"><strong>PHASE 2</strong><span>ENROLLING</span></div><div className="info"><header className="logoSection"><img src={"/images/gladiator-uc-trial-graphic-image.svg"} alt="gladiator UC trial" /></header><p><strong>GLADIATOR UC</strong> is a Phase 2 52-week induction and maintenance study evaluating the safety and efficacy of once-daily, oral etrasimod in patients with moderately active ulcerative colitis (UC). Patients with a modified Mayo Score of 4-6 and endoscopic subscore ≥1 are eligible to participate in GLADIATOR UC (additional participation criteria apply).</p>
			<ul><li>Patients that are ineligible for many UC trials due to lower disease activity may be eligible to participate in GLADIATOR UC</li><li>
Patients can remain on 5-aminosalicylic acid (5-ASA) and low-dose corticosteroids throughout the trial</li><li>
Patients are randomized 2:1 to etrasimod and placebo</li><li>
The treat-through design of the GLADIATOR UC trial does not re&#8209;randomize patients at the transition from induction to maintenance</li><li>
Patients are eligible to enter an open-label extension and receive investigational drug for up to 5 years</li></ul><div className="link"><a href="https://clinicaltrials.gov/ct2/results?cond=&term=APD334-210&cntry=&state=&city=&dist="  target="_blank"><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT04607837, NCT03950232</div><span className="legal">Etrasimod is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend etrasimod for any use.</span></div></div>
		
		
		
		<div className="pipeline-row"><div className="phase"><strong>PHASE 2</strong><span>ENROLLING</span></div><div className="info"><header className="logoSection"><img src={"/images/voyage-trial-graphic-image.svg"} alt="voyage trial" /></header>
		  <p><strong>VOYAGE</strong> is a Phase 2 dose-ranging clinical trial evaluating the safety and efficacy of once-daily, oral etrasimod in adult patients with eosinophilic esophagitis (EoE).</p>
          <ul>
            <li>Patients are randomized 3:3:2 to etrasimod 1 mg, etrasimod 2 mg, and placebo for a 24-week double-blind treatment period</li>
            <li>During the 28-week extended treatment period, patients in the etrasimod groups continue etrasimod, while patients in the placebo group are re&#8209;randomized to one of the etrasimod dose groups</li>
          </ul>
          <div className="link"><a href="https://clinicaltrials.gov/ct2/show/NCT04682639"  target="_blank" ><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT04682639</div><span className="legal">Etrasimod is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend etrasimod for any use.</span></div></div>
		
		
	  <div className="pipeline-row">
	    <div className="phase"><strong>PHASE 2/3</strong><span>RECRUITING</span></div><div className="info"><header className="logoSection"><img src={"/images/cultivate-trial-graphic-image.svg"} alt="cultivate trial" /></header>
		  <p><strong>CULTIVATE</strong> is a Phase 2/3 clinical trial program evaluating the efficacy and safety of once-daily, oral etrasimod in patients with moderately to severely active Crohn&rsquo;s disease (CD).</p>
          <ul>
            <li>The CULTIVATE clinical trial program is designed as a Phase 2/3 program to facilitate an operationally seamless transition from the Phase 2 portion of the study to the Phase 3 portion</li>
            <li>The operationally seamless design helps ensure continuity for patients, investigators, and study staff, and maintains patient access to investigational study drug throughout the development process</li>
          </ul>
          <div className="link"><a href="https://clinicaltrials.gov/ct2/show/NCT04173273"  target="_blank"><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT04173273 </div><span className="legal">Etrasimod is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend etrasimod for any use.</span></div></div>
		
		<div className="pipeline-row">
		  <div className="phase"><strong>PHASE 3</strong><span>ENROLLMENT COMPLETE</span></div><div className="info"><header className="logoSection"><img src={"/images/elevate-trial-graphic-image.svg"} alt="elevate trial" /></header>
		  <p><strong>ELEVATE UC</strong> is a Phase 3 program that includes ELEVATE 52, a 52-week induction and maintenance study, and ELEVATE 12, a 12&#8209;week induction study. The ELEVATE UC program is evaluating the efficacy and safety of once-daily, oral etrasimod in patients with moderately to severely active UC.</p>
          <ul>
            <li>Patients can remain on 5-aminosalicylic acid (5-ASA) and low-dose corticosteroids throughout the trial</li>
            <li>Patients are randomized 2:1 to etrasimod and placebo</li>
            <li>The treat-through design of the ELEVATE 52 trial does not
              re&#8209;randomize patients at the transition from induction to maintenance</li>
            <li>Patients are eligible to enter an open-label extension and receive the investigational drug for up to 5 years</li>
          </ul>
          
          <div className="link"><a href="https://clinicaltrials.gov/ct2/results?cond=&term=%22ELEVATE+UC%22&cntry=US&state=&city=&dist=" target="_blank"><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT03945188, NCT03996369, NCT03950232 </div><span className="legal">Etrasimod is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend etrasimod for any use.</span></div></div>
		
		<div className="pipeline-row">
		  <div className="phase"><strong>PHASE 2</strong><span>COMPLETE</span></div><div className="info"><header className="logoSection"><img src={"/images/oasis-trial-graphic-image.svg"} alt="oasis trial" /></header>
		  <p><strong>OASIS</strong> was a Phase 2 12&#8209;week randomized, placebo-controlled trial evaluating the safety and efficacy of two doses of <nobr>once-daily</nobr>, oral etrasimod in patients with moderately to severely active UC.</p>
          <ul>
            <li>Patients were randomized 1:1:1 to etrasimod 1 mg, etrasimod 2 mg, or placebo</li>
            <li>Patients who completed the 12&#8209;week induction study were eligible to enter an open-label extension study of up to 40 weeks&rsquo; duration</li>
          </ul>
       
          <div className="link"><a href="https://clinicaltrials.gov/ct2/results?cond=&term=APD334-003+&cntry=&state=&city=&dist"  target="_blank"><img src={"/images/learn-more.svg"} alt="Learn More" /></a>ClinicalTrials.gov: NCT02447302, NCT02536404</div><span className="legal">Etrasimod is an investigational drug and is not currently approved for use by any health authority. This information is not intended to promote or recommend etrasimod for any use.</span></div></div>
	</section>
<section className="spacer"></section>
	
	  <section className="wrap"><header className="orange rule"><h1>Etrasimod <span>key references</span></h1></header>
	  <div className="scrollBox"><div className="filter-area"><img src={"/images/filter-open.svg"} className="mobileFilterBtn"/>
	<div className="filter-controls publications">
			  
		   
			  <label className="container" ><strong>Select All</strong>
 			  <input type="checkbox" checked={isChecked1}  onChange={handleOnChange1} name="EtrasimodAll" value="EtrasimodAll" id="EtrasimodAll"    />
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Preclinical Studies
 			  <input type="checkbox"  checked={isChecked2}  onChange={handleOnChange2} name="EtrasimodPreclinical" value="EtrasimodPreclinical" id="EtrasimodPreclinical"/>
				  <span className="checkmark"></span>
			  </label>
	
			  <label className="container" >Phase 1 Studies
 			  <input type="checkbox"  checked={isChecked3}  onChange={handleOnChange3} name="Phase1" value="EtrasimodPhase1" id="EtrasimodPhase1"/>
				  <span className="checkmark"></span>
			  </label>
			  
			  <label className="container" >Phase 2 OASIS Study
 			  <input type="checkbox" checked={isChecked4}  onChange={handleOnChange4}/>
				  <span className="checkmark"  name="EtrasimodPhase2" value="EtrasimodPhase2" id="EtrasimodPhase2"></span>
			  </label>
	<br />
	
	
			  
			  
		  </div>
		  <div className="filter-results publications"> 
	{renderFilteredData()}
	

		  </div>
	 </div>
	    </div>
	</section>
	 <section className="spacer end"></section>
	<footer className="definitions">
 GI=gastrointestinal; IBD=inflammatory bowel disease; MOA=mechanism of action.<br />
	 <span>Arena is a clinical stage pharmaceutical company that does not have any medicines or products approved for use by any health authority.</span>
</footer>
  </Layout>
)
}

	
export default Etrasimod
